import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Shared/Navbar';
import Footer from './components/Shared/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Joinus from './pages/Joinus';
import Contact from './pages/Contact';
import CeoMessage from './pages/CeoMessage';
import ComingSoon from './pages/ComingSoon'; 
import './App.css';

function App() {
  return (
    <Router>
      <Navbar />
      <div className='mt-[75px] md:mt-[65px]'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/join-us" element={<Joinus />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/ceo-message" element={<CeoMessage />} /> */}

          {/* Wildcard route for all undefined paths */}
          <Route path="/group-ceo-message" element={<ComingSoon />} />
          <Route path="/news" element={<ComingSoon />} />
          <Route path="/events" element={<ComingSoon />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;

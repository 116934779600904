import React, { useState, useEffect, useCallback } from "react";
import handShake from "../assets/images/coming soon BG.png";
import whiteLogo from "../assets/images/M.ai_Logo_white.png";
import webLang from "../assets/images/web_lang.png";
import scrollUp from "../assets/images/Scroll_up.png";

const ComingSoon = () => {
  const [borderWidth, setBorderWidth] = useState(15); // Initial border width
  const [animate, setAnimate] = useState(false);
  const text = "Coming Soon";
  const [activeIndex, setActiveIndex] = useState(-1); // Track how many characters are red

  useEffect(() => {
    let timeoutIds = [];

    text.split("").forEach((_, index) => {
      const timeoutId = setTimeout(() => {
        setActiveIndex((prev) => prev + 1);
      }, index * (3000 / text.length)); // Spread over 3 seconds
      timeoutIds.push(timeoutId);
    });

    return () => timeoutIds.forEach((id) => clearTimeout(id)); // Cleanup on unmount
  }, [text]);

  // Function to handle scroll event
  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY;
    const viewportHeight = window.innerHeight;

    // Set border width based on scroll position
    if (scrollTop < viewportHeight) {
      setBorderWidth(50); // Trigger animation when within the viewport
      setAnimate(true);
    } else {
      setBorderWidth(15); // Reset border width when scrolled beyond 100vh
      setAnimate(false);
    }
  }, []);

  useEffect(() => {
    // Initial animation on page load
    setAnimate(true);

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const element = document.querySelector(".main-diagonal");
    if (animate && element) {
      // Force a reflow to restart the animation
      element.classList.remove("animate-border");
      void element.offsetWidth; // Trigger a reflow
      element.classList.add("animate-border");
    }
  }, [animate]);
  return (
    <div className="bottom-0 fixed w-full">
      <div id="join-us-landing">
        <div className="h-[81vh] relative">
          <img src={handShake} className="h-full w-full object-cover" />
        </div>

        <div
          className="main-diagonal"
          style={{ "--border-width": `${borderWidth}%` }}
        ></div>

        <div className="flex flex-col absolute top-36 md:top-24 z-10 mt-28 md:mt-8 xl:mt-0 items-center px-5 md:px-0">
          <div className="md:px-24">
            <div
              className="text-[32px] md:text-[72px] transition-text"
              style={{ fontFamily: "Quantum rise" }}
            >
              {text.split("").map((char, index) => (
                <span
                  key={index}
                  className={`char ${index <= activeIndex ? "active" : ""}`}
                >
                  {char}
                </span>
              ))}
            </div>

            {/* <h1
              className="text-[32px] md:text-[72px] transition-text"
              style={{ color: "#E41636", fontFamily: "Quantum rise" }}
            >
              Coming Soon
            </h1> */}
            <p className="text-[16px] md:text-[24px] w-full lg:w-full">
              Elevating Your Tech Experience - Stay Tuned
            </p>
          </div>
        </div>
      </div>
      <div
        className="px-4 md:px-10 lg:px-32 font-sans relative"
        style={{ backgroundColor: "#080808", color: "#c3c3c6" }}
      >
        <div
          className="border-t border-gray-600 py-6 px-7"
          style={{ color: "#c3c3c6" }}
        >
          {/* Bottom Section */}
          <div className="flex flex-col md:flex-row justify-between mt-5 items-center">
            <p className="text-base text-center md:text-left">
              Copyright © 2024 M&M Ltd.
              <span className="hidden md:inline"> All Rights Reserved.</span>
            </p>
            <div className="flex md:hidden mt-1">All Rights Reserved.</div>

            <div className="flex space-x-6 mt-10 md:mt-0 text-base">
              <a
                href="#"
                className="hover:text-[#e1002a] transition-colors duration-300"
              >
                Terms of Use
              </a>
              <a
                href="#"
                className="hover:text-[#e1002a] transition-colors duration-300"
              >
                Privacy Policy
              </a>

              <a
                href="#"
                className="hover:text-[#e1002a] transition-colors duration-300 hidden md:flex"
              >
                Cookie Preferences
              </a>
            </div>
            <div className="mt-10 flex md:hidden">
              <a
                href="#"
                className="hover:text-[#e1002a] transition-colors duration-300 "
              >
                Cookie Preferences
              </a>
            </div>
          </div>
        </div>

        <div
          className="absolute bottom-0 right-0 py-9 px-12"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <img src={scrollUp} alt="scroll up" className="cursor-pointer" />
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;

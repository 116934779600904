import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import whiteLogo from "../../assets/images/M.ai_Logo_white.png";
import webLang from "../../assets/images/web_lang.png";
import scrollUp from "../../assets/images/Scroll_up.png";

const Footer = () => {
  const location = useLocation(); // Get the current route
  const [showLinks, setShowLinks] = useState(true); // State to track whether to show links

  // List of allowed paths
  const allowedPaths = ["/", "/about", "/join-us", "/contact"];

  // Effect to check if the path is not included in the allowed paths
  useEffect(() => {
    if (!allowedPaths.includes(location.pathname)) {
      setShowLinks(false); // Hide links if path is not in allowed paths
    } else {
      setShowLinks(true); // Show links if path is in allowed paths
    }
  }, [location.pathname]);

  return (
    <>
      {showLinks && (
        <div
          className="py-10 px-4 md:px-10 lg:px-32 font-sans relative"
          style={{ backgroundColor: "#080808", color: "#c3c3c6" }}
        >
          <div>
            <div className="flex flex-col md:flex-row justify-between items-center text-center md:text-start md:items-stretch space-y-8 md:space-y-0">
              <div>
                <img src={whiteLogo} alt="logo" className="h-6" />
              </div>
              <div className="space-y-3">
                {/* Menu Section */}
                <div style={{ color: "#e1002a" }}>Menu</div>
                <div className="font-sans space-y-2 text-sm md:text-[14.4px]">
                  <a
                    href="/"
                    className="cursor-pointer hover:text-[#e1002a] block transition-colors duration-300"
                    // onClick={() => handleButtonClick("", "home-landing")}
                  >
                    Home
                  </a>
                  <a
                    href="/about"
                    className="cursor-pointer hover:text-[#e1002a] block transition-colors duration-300"
                    // onClick={() => handleButtonClick("about", "about-landing")}
                  >
                    About Us
                  </a>
                  <a
                    href="/join-us"
                    className="cursor-pointer hover:text-[#e1002a] block transition-colors duration-300"
                    // onClick={() => handleButtonClick("join-us", "join-us-landing")}
                  >
                    Join Us
                  </a>
                  <a
                    href="/contact"
                    className="cursor-pointer hover:text-[#e1002a] block transition-colors duration-300"
                    // onClick={() => handleButtonClick("contact", "contact-landing")}
                  >
                    Contact
                  </a>
                </div>
              </div>

              <div className="space-y-3">
                {/* Links Section */}
                <div style={{ color: "#e1002a" }}>Links</div>
                <div className="font-sans space-y-2 text-sm md:text-[14.4px]">
                  <a
                    href="/news"
                    className="cursor-pointer hover:text-[#e1002a] block transition-colors duration-300"
                  >
                    News
                  </a>
                  <a
                    href="/events"
                    className="cursor-pointer hover:text-[#e1002a] block transition-colors duration-300"
                  >
                    Events
                  </a>
                  <a
                    href="/join-us"
                    className="cursor-pointer hover:text-[#e1002a] block transition-colors duration-300"
                    // onClick={() => handleButtonClick("join-us", "join-us-landing")}
                  >
                    Careers
                  </a>
                  <a
                    href="/contact"
                    className="cursor-pointer hover:text-[#e1002a] block transition-colors duration-300"
                    // onClick={() => handleButtonClick("contact", "contact-landing")}
                  >
                    Sitemap
                  </a>
                </div>
              </div>

              <div className="flex flex-row md:flex-col justify-between items-end">
                <div className="flex space-x-4 text-black">
                  <a href="#">
                    <i className="fab fa-facebook-f bg-white rounded-full px-3 py-1 text-xl"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-x-twitter bg-white rounded-full px-2 py-1 text-xl"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-linkedin-in bg-white rounded-full px-2 py-1 text-xl"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-youtube bg-white rounded-full px-2 py-1 text-xl"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-instagram bg-white rounded-full px-2 py-1 text-xl"></i>
                  </a>
                </div>
                <div className="flex cursor-pointer space-x-2 -mb-5">
                  <img src={webLang} alt="language" />
                  <span>EN</span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="border-t border-gray-600 mt-8 pt-6 px-7"
            style={{ color: "#c3c3c6" }}
          >
            {/* Bottom Section */}
            <div className="flex flex-col md:flex-row justify-between mt-5 items-center">
              <p className="text-base text-center md:text-left">
                Copyright © 2024 M&M Ltd.
                <span className="hidden md:inline"> All Rights Reserved.</span>
              </p>
              <div className="flex md:hidden mt-1">All Rights Reserved.</div>

              <div className="flex space-x-6 mt-10 md:mt-0 text-base">
                <a
                  href="#"
                  className="hover:text-[#e1002a] transition-colors duration-300"
                >
                  Terms of Use
                </a>
                <a
                  href="#"
                  className="hover:text-[#e1002a] transition-colors duration-300"
                >
                  Privacy Policy
                </a>

                <a
                  href="#"
                  className="hover:text-[#e1002a] transition-colors duration-300 hidden md:flex"
                >
                  Cookie Preferences
                </a>
              </div>
              <div className="mt-10 flex md:hidden">
                <a
                  href="#"
                  className="hover:text-[#e1002a] transition-colors duration-300 "
                >
                  Cookie Preferences
                </a>
              </div>
            </div>
          </div>

          <div
            className="absolute bottom-0 right-0 py-9 px-12"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <img src={scrollUp} alt="scroll up" className="cursor-pointer" />
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
